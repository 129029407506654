import React from 'react';
import * as styles from "./styles.module.scss";
import { navigate } from 'gatsby';

export default function ToolCard({name, description, ...rest}) {
  return (
    <div className={styles.container}>
      <img className={styles.toolImage} src={rest?.image} alt={name}/>
      <h3 className={styles.title}>{name}</h3>
      <p className={styles.desc}>{description}</p>
      <a className={styles.link} onClick={() => navigate(`/${rest?.toLink}`)}>See more</a>
      {/* <button onClick={() => navigate("/pricing")} className={styles.button}>Buy</button> */}
    </div>
  )
}
