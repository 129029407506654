// extracted by mini-css-extract-plugin
export var activeTab = "styles-module--activeTab--d5664";
export var btn = "styles-module--btn--bedff";
export var buttons = "styles-module--buttons--eec68";
export var container = "styles-module--container--b7967";
export var content = "styles-module--content--b53ed";
export var desc = "styles-module--desc--065e5";
export var subtitle = "styles-module--subtitle--851d3";
export var tabs = "styles-module--tabs--5676a";
export var title = "styles-module--title--64d7f";
export var video = "styles-module--video--45164";