import * as styles from "./FreeVideos.module.scss";
import React, { useEffect, useState } from "react";
import { BsFillPlayFill } from "react-icons/bs";
import circle from "../../../images/circle.png";
import { navigate } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";

function FreeVideos({ extraClass }) {
  const [state, setVideo] = useState("");
  const [width, setWidth] = useState(0)
  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);
  const data = [
    {
      title: "Re-imagining Video Conferencing",
      url: "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674065005/mwvideos/intromodules_isudka.mp4",
      image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703691608/web/Rectangle_15336_iyuvup.png",
      toLink: "wizr-connect"
    },
    {
      title: "Expanding virtual presences with 3D Spaces",
      url: "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674052402/mwvideos/wconnectindex2-2_egbj6i.mp4",
      image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703691608/web/Rectangle_15335_hhzeef.png",
      toLink: "3d-spaces"
    },
  ];
  return (
    <>
      <div className={`${styles.container} ${styles[extraClass]}`}>
        <h1 className={styles.title}>How We Think</h1>
        <p className={styles.description}>Case examples from our innovative video work. We keep innovating and re-imagining the familiar.</p>
        <div className={styles.data}>
          {data.map((item, i) => {
            return (
              <AniLink to={`/${item?.toLink}`}>
                <div key={i} className={styles.card}>
                  <div className={styles.thumbnail}>
                    <img onClick={() => setVideo(item.url)} src={item.image} alt="vd" />
                    <h3>{item.name}</h3>
                  </div>
                  <div className={styles.bottom}>
                    <h2>{item.title}, <a className={styles.link} onClick={() => navigate(`/${item?.toLink}`)}>check it out</a></h2>
                  </div>
                </div>
              </AniLink>
            );
          })}
        </div>
      </div>
    </>
  );
}
export default FreeVideos;
