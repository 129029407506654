import React, { useState } from "react";
import * as styles from "./styles.module.scss";
import { navigate } from "gatsby";

export default function TopBanner() {
  const [activeTab, setActiveTab] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const videos = [
    "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1727093634/LibraryPromo_Short_ot5erv.mp4",
    "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1727093520/Super_Promo_-_Studio_-_Fianl_Video_Valon_V3_-_SoMe_24_2_ckniz9.mp4",
    "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1727093696/MPublisher_Promo_Short_q3n1qj.mp4",
  ];

  const handleVideoEnd = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setActiveTab((prevTab) => (prevTab + 1) % videos.length);
      setIsTransitioning(false);
    }, 500); 
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>Designed to Engage.</h1>
        <h3 className={styles.subtitle}>Innovating work tools.</h3>
        <p className={styles.desc}>
          Master your communications to sell, market, convince and collaborate
          better. Expand your ways to make impact and communicate your brand.
        </p>
        <div className={styles.buttons}>
          <button
            onClick={() =>
              window.open("https://accounts.masterwizr.com/sign-up", "_blank")
            }
            className={styles.btn}
          >
            Enjoy for free
          </button>
          <button onClick={() => navigate("/pricing")} className={styles.btn}>
            Explore plans
          </button>
        </div>
      </div>

      <div className={styles.tabs}>
        <button
          className={activeTab === 0 ? styles.activeTab : ""}
          onClick={() => setActiveTab(0)}
        >
          Organize & View
        </button>
        <button
          className={activeTab === 1 ? styles.activeTab : ""}
          onClick={() => setActiveTab(1)}
        >
          Present & Share
        </button>
        <button
          className={activeTab === 2 ? styles.activeTab : ""}
          onClick={() => setActiveTab(2)}
        >
          Create & Educate
        </button>
      </div>

      <div
        className={`${styles.videoWrapper} ${
          isTransitioning ? styles.fadeOut : styles.fadeIn
        }`}
      >
        <video
          className={styles.video}
          src={videos[activeTab]}
          autoPlay
          muted
          onEnded={handleVideoEnd} // Trigger this function when the video ends
        />
      </div>
    </div>
  );
}
