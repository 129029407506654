import React from "react";
import * as styles from "./DelightSection.module.scss";
import image1 from "../../../images/row1.png";
import image2 from "../../../images/row2.png";
import image3 from "../../../images/row3.png";
import image4 from "../../../images/row4.png";
import { Link } from "gatsby";

function DelightSection() {
  const data = [
    {
      title: "Products",
      url: "/products",
      image: image1,
      description:
        `Our product modules are continuously updated and expanded to provide you with the best experience possible. Stay informed about our latest features and benefits. `,
    },
    {
      title: "3D Spaces",
      image: image2,
      url: "/3d-spaces",
      description:
        `Virtual presence matters more than ever. Our 3D Spaces are a key component of the WiZR Connect video conferencing system, providing an immersive experience. `,
    },
    {
      title: "Articles",
      image: image3,
      url: "/articles",
      description:
        `As the art of communication continues to evolve, we keep up with the latest developments. Our Magazine addresses technology, remote work, communication, and productivity.`,
    },
    {
      title: "Pricing",
      image: image4,
      url: "pricing",
      description:
        `We offer license plans and master programs designed to help you optimize the impact of our product modules. Small or large companies, we have you covered.`,
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Present with Confidence</h1>
        <p>Connect Effectively.</p>
      </div>
      <div className={styles.cards}>
        {data.map((item, i) => {
          return (
            <Link to={item.url}>
              <div className={styles.item}>
                <div
                  style={{ backgroundImage: `url(${item?.image})` }}
                  key={i}
                  className={styles.card}
                >
                  <h2>{item.title}</h2>
                  <p>Explore Now</p>
                </div>
                <div className={styles.desc}>
                  <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
export default DelightSection;
