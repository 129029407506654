import React, { useState } from "react";
import * as styles from "./faqSection.module.scss";
import Faq from "../../atoms/Faq";

const FaqSection = () => {
  const [faqs, setfaqs] = useState([
    {
      question: "What inspired MASTER WiZR?",
      answer:
        "We combined the finest attributes of Apple, Spotify, Netflix, and Microsoft to redefine how people present, communicate, and meet. With Master WiZR, anyone can create mesmerizing and instinctive user interfaces to portray their ideas effectively.",
      open: true,
    },
    {
      question: "How much does it cost?",
      answer:
        "Our services include both Software (SaaS) and Presentations (PaaS). The pricing is affordable, from students and freelancers to small businesses and large corporations. Prices vary from $10-80 per month depending on usage and the number of licenses. To get started, ",
      link:"contact",
      linktext:"simply send us an inquiry",
      open: false,
    },
    {
      question: "What can I do on MASTER WiZR?",
      answer:
        "MASTER WiZR is a cloud-based presentation system that offers two modules: LIBRARY and STUDIO. The LIBRARY helps you arrange and present your content, while the STUDIO allows you to personalize and distribute your presentations in fresh new ways. Our ultimate goal is to revolutionize the art of presentations by providing a tool that streamlines content organization, presentation, and sharing processes.",
      open: false,
    },
    {
      question: "What makes MASTER WiZR unique?",
      answer:
        "We focus on visually improving your content, making it easy to organize and share. Our aim is to become the world's premier presentation and meeting tool that will boost your communication and impact. Witness the transformation with MASTER WiZR's before-and-after results.",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        }
        // If you want to close other FAQs when you click one of them just uncomment the part below
        // else {
        //     faq.open = false;
        // }

        return faq;
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <h3>FAQ</h3>
        <h2>In case you missed anything</h2>
        {faqs.map((faq, i) => (
          <Faq index={i} faq={faq} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </div>
  );
};

export default FaqSection;
