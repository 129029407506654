import React, { useEffect, useCallback, useState } from "react";
import * as styles from "./controlPanel.module.scss";
import StyledButton from "../../atoms/StyledButton";
import { IoIosArrowForward } from "react-icons/io";
const ControlPanel = () => {
  const [width,setWidth]=useState(0)
  useEffect( () => {
      setWidth(window.innerWidth);
  },[]);
  return (
 <>
 {width && width >= 768 ?
    <div className={styles.container}>
    <div className={styles.innerContainer}>
      <div className={styles.upper}>
        <h2 className={styles.title}>Stand Out From The Rest</h2>
        <p className={styles.description}>
          Use world class design tools and templates to customize, personalize
          and optimize your presentations.{" "}
        </p>

        <div className={styles.button}>
          <StyledButton
            title="Sign Up for a Free Trial"
            extraClass="bgShadow"
            icon={<IoIosArrowForward />}
            link="/contact"
            email={true}
          />
        </div>
      </div>
      <img
      alt="pres"
        src={
          "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680902647/web%20images/docview11-8f67459b865d5c445f8874f19578fb51_1_ss5iiw.jpg"
        }
      />
    </div>
  </div>
  :
  <div className={styles.mobileContainer}>
    <h1>Stand Out From <br/> The Rest</h1>
    <p>Use world class design tools and templates to customize, personalize and optimize your presentations.</p>
    <img
      alt="3D design product demo of Master Wizr Studio showcasing a showroom cover page."
        src={
          "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1681151761/web%20images/StudioNew_2_1_1_puab8q.png"
        }
      />
  </div>
 }
 </>
  );
};

export default ControlPanel;
