import React from 'react';
import *  as styles from "./styles.module.scss";
import { navigate } from "gatsby";


export default function ArticleHome() {
  return (
    <div className={styles.banner}>
           <button
              onClick={() => navigate(`/articles`)}
              className={styles.btn}
            >
              Go to Articles
            </button>
    </div>
  )
}
