import React from "react";
import * as styles from './bannerTekst.module.scss';

function index() {
  return (
    <div className={styles.containTitle}>
      <h1 data-aos="zoom-in" data-aos-delay="100">Exceptional Work Solutions</h1>
      {/* <p data-aos="zoom-in" data-aos-delay="200">
      Designed to sort all your remote office needs and beyond
      </p> */}
      <p data-aos="zoom-in" data-aos-delay="200">
      <span>Your Innovate Workspace: </span> Transforming your Google and Microsoft Suite Experience
      </p>
    </div>
  );
}

export default index;
