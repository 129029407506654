import React from 'react';
import * as styles from './faq.module.scss';
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";

const Faq = ({faq, index, toggleFAQ,extraClass}) => {
    return (
        <div
            className={`${styles.row} ${faq.open && styles.active } ${styles[extraClass]}`}
            key={index}
            onClick={() => toggleFAQ(index)}
            onKeyDown={() => toggleFAQ(index)}
        >
            <div className={styles.title}>
                <h3>{faq.question}</h3>
                <div className={styles.circle}>
                    <FontAwesomeIcon
                        icon={faPlus}
                        className={styles.plusCircle}
                    />
                </div>
            </div>
            <div className={styles.description}>
                <p>{faq.answer} <Link to={'/' + faq.link}>{faq.linktext}.</Link></p>
            </div>
            {index <= 2 && <hr />}
        </div>
    );
};

export default Faq;
